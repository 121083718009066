/**
 *
 * Invoice
 * @class Invoice
 */
export class Invoice {

  /**
     * Creates an instance of Invoice.
     * @param { InvoiceObj } invoiceObj
     * @memberof Invoice
     */

  constructor(invoiceObj) {
    if(typeof invoiceObj === 'undefined' || invoiceObj === null){
      invoiceObj = {};
    }

    this.id         = invoiceObj.id || null;
    this.order_id   = invoiceObj.order_id || null;
    this.invoice_id = invoiceObj.invoice_id || null;
    this.key        = invoiceObj.key || null;
    this.receipt    = invoiceObj.receipt || 0.00;
    this.protocol   = invoiceObj.protocol || null;
    this.number     = invoiceObj.number || 0.00;
    this.created_at = new Date(invoiceObj.created_at || null);
  }

  /**
     * Creates an instance of Invoice.
     * @param { InvoiceObj } invoiceObj
     * @memberof Invoice
     * @returns { Invoice }
     */
  static init(invoiceObj) {
    return new Invoice(invoiceObj);
  }
}
