/**
 *
 * SubscriptionItem
 * @class SubscriptionItem
 */

export class SubscriptionItem {

  /**
   * Creates an instance of SubscriptionItem.
   * @param { SubscriptionItemObj } itemObj
   * @memberof SubscriptionItem
   */

  constructor(itemObj) {
    if(typeof itemObj === 'undefined' || itemObj === null){
      itemObj = {};
    }

    this.item_id         = itemObj.id || null;
    this.description     = itemObj.description || 0.00;
    this.price           = itemObj.price || 0.00;
    this.discount        = itemObj.discount;
    this.discount_amount = itemObj.price * itemObj.quantity * itemObj.discount / 100;
    this.amount          = itemObj.price * itemObj.quantity - this.discount_amount || 0.00;
    this.quantity        = itemObj.quantity || 0;
    this.type            = itemObj.type || null;

    if(typeof itemObj.created_at !== "undefined"){
      this.created_at = new Date(itemObj.created_at);
    }
  }

  /**
   * Creates an instance of SubscriptionItem.
   * @param { SubscriptionItemObj } itemObj
   * @memberof SubscriptionItem
   * @returns { SubscriptionItem }
   */
  static init(itemObj) {
    return new SubscriptionItem(itemObj);
  }

}
