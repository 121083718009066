import {Company} from "./Company";
import {Package} from "./Package";
import {SubscriptionCancellationReason} from "./SubscriptionCancellationReason";

/**
 *
 * subscriptionCancellation
 * @class subscriptionCancellation
 */

export class SubscriptionCancellation {

  /**
     * Creates an instance of subscriptionCancellation.
     * @param { obj } moneyObj
     * @memberof subscriptionCancellation
     */

  constructor(obj) {
    if(typeof obj === 'undefined' || obj === null){
      obj = {};
    }

    this.id              = obj.id || null;
    this.package_id      = obj.package_id || null;
    this.subscription_id = obj.subscription_id || null;
    this.company_id      = obj.company_id || null;
    this.package_id      = obj.package_id || null;
    this.reason_id       = obj.reason_id || null;

    this.subscription    = obj.subscription || null;
    this.package_agent   = obj.package_agent || 1;
    this.aditional_agent = obj.aditional_agent || 0;
    this.whatsapp        = obj.whatsapp  || 0;

    this.cycle           = obj.cycle || null;
    this.amount          = obj.amount || 0.00;

    this.currency        = obj.currency || 'brl';
    this.requester       = obj.requester || null;
    this.status          = obj.status || null;
    this.hiring_date     = obj.hiring_date || new Date();
    this.cancellation_date = obj.cancellation_date || new Date();
    this.details         = obj.details || null;
    this.observations    = obj.observations || null;

    this.created_at      = new Date();
    this.company         = new Company();
    this.package         = new Package();
    this.reason          = new SubscriptionCancellationReason();

    if(typeof obj.hiring_date !== "undefined"){
      this.hiring_date  = new Date(obj.hiring_date);
    }

    if(typeof obj.cancellation_date !== "undefined"){
      this.cancellation_date  = new Date(obj.cancellation_date);
    }

    if(typeof obj.created_at !== "undefined"){
      this.created_at  = new Date(obj.created_at);
    }

    if(typeof obj.package !== "undefined"){
      this.package = new Package(obj.package);
    }

    if(typeof obj.company !== "undefined"){
      this.company = new Company(obj.company);
    }

    if(typeof obj.reason !== "undefined"){
      this.reason = new SubscriptionCancellationReason(obj.reason);
    }

    if(typeof obj.items !== "undefined"){
      this.items = obj.items;
    }

    switch (obj.status) {
      case 'required':
        this.class = 'badge badge-warning';
        break;
      case 'queued':
        this.class = 'badge badge-info';
        break;
      case 'processed':
        this.class = 'badge badge-success';
        break;
      case 'canceled':
        this.class = 'badge badge-dark';
        break;
    }
  }

  /**
     * Creates an instance of subscriptionCancellation.
     * @param { obj } moneyObj
     * @memberof subscriptionCancellation
     * @returns { subscriptionCancellation }
     */
  static init() {
    return new SubscriptionCancellation();
  }

}


