/**
 *
 * SubscriptionCancellationReason
 * @class SubscriptionCancellationReason
 */
export class SubscriptionCancellationReason {

  /**
     * Creates an instance of SubscriptionCancellationReason.
     * @param { SubscriptionCancellationReasonObj } eventObj
     * @memberof SubscriptionCancellationReason
     */

  constructor(eventObj) {
    if(typeof eventObj === 'undefined' || eventObj === null){
      eventObj = {};
    }

    this.id         = eventObj.id || null;
    this.name       = eventObj.name || null;
    this.label      = eventObj.label || null;
    this.created_at = new Date();

    if(typeof eventObj.created_at !== "undefined"){
      this.created_at = new Date(eventObj.created_at);
    }
  }

  /**
     * Creates an instance of SubscriptionCancellationReason.
     * @param { SubscriptionCancellationReasonObj } eventObj
     * @memberof SubscriptionCancellationReason
     * @returns { SubscriptionCancellationReason }
     */
  static init(eventObj) {
    return new SubscriptionCancellationReason(eventObj);
  }

}
