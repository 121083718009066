/**
 *
 * CreditCard
 * @class CreditCard
 */

export class CreditCard {

  /**
     * Creates an instance of CreditCard.
     * @param { CreditCardObj } creditCardObj
     * @memberof CreditCard
     */

  constructor(creditCardObj) {
    if(typeof creditCardObj === 'undefined' || creditCardObj === null){
      creditCardObj = {};
    }

    this.id         = creditCardObj.id || null;
    this.company_id = creditCardObj.company_id || null;
    this.brand      = creditCardObj.brand || null;
    this.alias      = creditCardObj.alias|| null;
    this.number     = creditCardObj.number || null;
    this.ccv        = null;
    this.due        = null;
    this.default    = creditCardObj.default || null;
    this.code       = creditCardObj.code || null;
    this.created_at = new Date();

    if(typeof creditCardObj.created_at !== "undefined"){
      this.created_at = new Date(creditCardObj.created_at);
    }
  }

  /**
     * Creates an instance of CreditCard.
     * @param { CreditCardObj } creditCardObj
     * @memberof CreditCard
     * @returns { CreditCard }
     */
  static init(creditCardObj) {
    return new CreditCard(creditCardObj);
  }

}

