import {SubscriptionEvent} from "./SubscriptionEvent";
import {SubscriptionItem} from "./SubscriptionItem";
import {Company} from "./Company";
import {Coupon} from "./Coupon";
import {Package} from "./Package";
import {SubscriptionCancellation} from "./SubscriptionCancellation";

/**
 *
 * subscription
 * @class subscription
 */

export class Subscription {

  /**
   * Creates an instance of subscription.
   * @param { subscriptionObj } moneyObj
   * @memberof subscription
   */

  constructor(subscriptionObj) {
    if(typeof subscriptionObj === 'undefined' || subscriptionObj === null){
      subscriptionObj = {};
    }

    this.id            = subscriptionObj.id || null;
    this.package_id    = subscriptionObj.package_id || null;
    this.coupon_id     = subscriptionObj.coupon_id || null;
    this.plan          = subscriptionObj.plan || null;
    this.package_agent = subscriptionObj.package_agent || 1;
    this.cycle         = subscriptionObj.cycle || null;
    this.is_due        = subscriptionObj.is_due || null;
    this.subtotal      = subscriptionObj.subtotal || null;
    this.discount      = subscriptionObj.discount || null;
    this.total         = subscriptionObj.total || null;
    this.active        = subscriptionObj.active || null;
    this.version       = subscriptionObj.version || null;
    this.suspended     = subscriptionObj.suspended || null;
    this.demo          = subscriptionObj.demo || false;
    this.observations  = subscriptionObj.observations || null;

    this.has_paid_package = subscriptionObj.has_paid_package || false;
    this.prorate_factor   = subscriptionObj.prorate_factor || false;

    this.package_name     = subscriptionObj.package_name || null;
    this.payment_method   = subscriptionObj.payment_method || null;
    this.payment_term     = subscriptionObj.payment_term || 0;

    this.cancellations    = subscriptionObj.cancellations || null;

    this.prices = {
      plan:            new SubscriptionItem(),
      aditional_agent: new SubscriptionItem(),
      whatsapp:        new SubscriptionItem(),
    };

    this.period_start = new Date();
    this.period_end   = new Date();
    this.created_at   = new Date();

    this.company       = {};
    this.coupon        = new Coupon();
    this.company       = new Company();
    this.package       = new Package();
    this.events        = [];
    this.items         = [];
    this.cancellations = [];

    if(typeof subscriptionObj.period_start !== "undefined"){
      this.period_start  = new Date(subscriptionObj.period_start);
    }

    if(typeof subscriptionObj.period_end !== "undefined"){
      this.period_end  = new Date(subscriptionObj.period_end);
    }

    if(typeof subscriptionObj.created_at !== "undefined"){
      this.created_at  = new Date(subscriptionObj.created_at);
    }

    if(typeof subscriptionObj.coupon !== "undefined"){
      this.coupon = new Coupon(subscriptionObj.coupon);
    }

    if(typeof subscriptionObj.package !== "undefined"){
      this.package = new Package(subscriptionObj.package);
    }

    if(typeof subscriptionObj.company !== "undefined"){
      this.company = new Company(subscriptionObj.company);
    }

    if(typeof subscriptionObj.events !== "undefined"){
      this.events = subscriptionObj.events.map(event=> {
        return new SubscriptionEvent(event);
      }) || [];
    }

    if(typeof subscriptionObj.cancellations !== "undefined"){
      this.cancellations = subscriptionObj.cancellations.map(event=> {
        return new SubscriptionCancellation(event);
      }) || [];
    }

    if(typeof subscriptionObj.items !== "undefined"){
      this.items = subscriptionObj.items.map(event=> {
        return new SubscriptionItem(event);
      }) || [];
    }

    this.aditional_agent = this.items.filter(function (item){ return item.type === 'agent' }).reduce( function(total, item){
      return total + item.quantity;
    }, 0) || 0;

    this.agent = this.package_agent + this.aditional_agent;

    this.whatsapp = this.items.filter(function (item){ return item.type === 'whatsapp' }).reduce( function(total, item){
      return total + item.quantity;
    }, 0) || 0;

    this.subtotal = this.items.reduce( function(total, item){
      return total + (item.price * item.quantity);
    }, 0);

    this.discount = this.items.reduce( function(total, item){
      return total + item.discount_amount;
    }, 0);

    this.total = this.subtotal - this.discount;
  }

  /**
   * Creates an instance of subscription.
   * @param { subscriptionObj } moneyObj
   * @memberof subscription
   * @returns { subscription }
   */
  static init() {
    return new Subscription();
  }

}