import { Company } from '@/models/Company';
import { Coupon }  from '@/models/Coupon';
import { Invoice }  from '@/models/Invoice';
import { OrderPayment } from "@/models/OrderPayment";
import { OrderNotification } from "@/models/OrderNotification";
import { OrderFailedPayment } from "@/models/OrderFailedPayment";

/**
 *
 * Order
 * @class Order
 */
export class Order {
  /**
     * Creates an instance of Order.
     * @param { OrderObj } orderObj
     * @memberof Order
     */

  constructor(orderObj) {
    if(typeof orderObj === 'undefined' || orderObj === null){
      orderObj = {};
    }


    this.id           = orderObj.id || null;
    this.company_id   = orderObj.company_id || null;
    this.coupon_id    = orderObj.coupon_id || null;
    this.description  = orderObj.description || null;
    this.currency     = orderObj.currency || null;
    this.amount       = orderObj.amount || 0.00;
    this.discount     = orderObj.discount || 0.00;
    this.final_amount = orderObj.final_amount || 0.00;
    this.code         = orderObj.code || null;
    this.status       = orderObj.status || null;
    this.source       = orderObj.source || null;
    this.url          = orderObj.url || null;
    this.method       = orderObj.method || null;
    this.token        = orderObj.token || null;
    this.observations = orderObj.observations || null;
    this.created_at   = new Date(orderObj.created_at || null);
    this.due          = new Date(orderObj.due || null);

    this.items           = [];
    this.payments        = [];
    this.invoices        = [];
    this.failed_payments = [];
    this.notifications   = [];

    this.company      = (orderObj.company) ? new Company(orderObj.company) : new Company();
    this.coupon       = (orderObj.coupon) ? new Coupon(orderObj.coupon) : new Coupon();

    this.class        = 'bg-primary';

    switch (orderObj.status) {
      case 'pendding':
        this.class = 'badge badge-warning';
        break;
      case 'processing':
        this.class = 'badge badge-info';
        break;
      case 'paid':
        this.class = 'badge badge-success';
        break;
      case 'due':
        this.class = 'badge badge-danger';
        break;
      case 'canceled':
        this.class = 'badge badge-dark';
        break;
      case 'draft':
        this.class = 'badge badge-dark';
        break;
      case 'failed':
        this.class = 'badge badge-danger';
        break;
    }

    if(orderObj.items){
      this.items = orderObj.items.map(item => {
        return item;
      });
    }

    if(orderObj.payments){
      this.payments = orderObj.payments.map(item => {
        return new OrderPayment(item);
      });
    }

    if(orderObj.invoices){
      this.invoices = orderObj.invoices.map(item => {
        return new Invoice(item);
      });
    }

    if(orderObj.failed_payments){
      this.failed_payments = orderObj.failed_payments.map(item => {
        return new OrderFailedPayment(item);
      });
    }

    if(orderObj.notifications){
      this.notifications = orderObj.notifications.map(item => {
        return new OrderNotification(item);
      });
    }
  }

  /**
     * Creates an instance of Order.
     * @param { OrderObj } orderObj
     * @memberof Order
     * @returns { Order }
     */
  static init(orderObj) {
    return new Order(orderObj);
  }
}
