/**
 *
 * CompanyLimit
 * @class CompanyLimit
 */
export class CompanyLimit {

  /**
     * Creates an instance of CompanyLimit.
     * @param { CompanyLimitObj } limitObj
     * @memberof CompanyLimit
     */

  constructor(limitObj) {
    if(typeof limitObj === 'undefined' || limitObj === 0){
      limitObj = {};
    }

    this.id                   = limitObj.id || null;
    this.company_id            = limitObj.company_id || null;

    this.account_manager = limitObj.account_manager || 0;
    this.action = limitObj.action || 0;
    this.advanced_trainning = limitObj.advanced_trainning || 0;
    this.agent_conected = limitObj.agent_conected || 0;
    this.api_call = limitObj.api_call || 0;
    this.app = limitObj.app || 0;
    this.archived_chat = limitObj.archived_chat || 0;
    this.attendance_history = limitObj.attendance_history || 0;
    this.attendance_queue = limitObj.attendance_queue || 0;
    this.attendance_reason = limitObj.attendance_reason || 0;
    this.auto_distribution = limitObj.auto_distribution || 0;
    this.automator = limitObj.automator || 0;
    this.base = limitObj.base || 0;
    this.block_contact = limitObj.block_contact || 0;
    this.bot = limitObj.bot || 0;
    this.chat = limitObj.chat || 0;
    this.chat_transcription = limitObj.chat_transcription || 0;
    this.contact = limitObj.contact || 0;
    this.contact_owner = limitObj.contact_owner || 0;
    this.custom_access_profile = limitObj.custom_access_profile || 0;
    this.custom_channel = limitObj.custom_channel || 0;
    this.custom_field = limitObj.custom_field || 0;
    this.custom_view = limitObj.custom_view || 0;
    this.dashboard = limitObj.dashboard || 0;
    this.department = limitObj.department || 0;
    this.email = limitObj.email || 0;
    this.export_cvs = limitObj.export_cvs || 0;
    this.export_flow = limitObj.export_flow || 0;
    this.file_upload = limitObj.file_upload || 0;
    this.flow = limitObj.flow || 0;
    this.flow_console = limitObj.flow_console || 0;
    this.flow_creation_help = limitObj.flow_creation_help || 0;
    this.general_access_profile = limitObj.general_access_profile || 0;
    this.group_attendance = limitObj.group_attendance || 0;
    this.help_center = limitObj.help_center || 0;
    this.holiday = limitObj.holiday || 0;
    this.id = limitObj.id || 0;
    this.import_flow = limitObj.import_flow || 0;
    this.in_flow = limitObj.in_flow || 0;
    this.integration_setup = limitObj.integration_setup || 0;
    this.internal_chat = limitObj.internal_chat || 0;
    this.internal_note = limitObj.internal_note || 0;
    this.ip_access_control = limitObj.ip_access_control || 0;
    this.leads_live = limitObj.leads_live || 0;
    this.login_scale = limitObj.login_scale || 0;
    this.master_flow = limitObj.master_flow || 0;
    this.messenger = limitObj.messenger || 0;
    this.mobile_app = limitObj.mobile_app || 0;
    this.mobile_sdk = limitObj.mobile_sdk || 0;
    this.monthly_monitoring = limitObj.monthly_monitoring || 0;
    this.multicompany = limitObj.multicompany || 0;
    this.omnichannel = limitObj.omnichannel || 0;
    this.organization = limitObj.organization || 0;
    this.out_flow = limitObj.out_flow || 0;
    this.print_chat = limitObj.print_chat || 0;
    this.quarterly_monitoring = limitObj.quarterly_monitoring || 0;
    this.record_audio = limitObj.record_audio || 0;
    this.report = limitObj.report || 0;
    this.segment = limitObj.segment || 0;
    this.setup = limitObj.setup || 0;
    this.shortcut = limitObj.shortcut || 0;
    this.single_signon = limitObj.single_signon || 0;
    this.sla = limitObj.sla || 0;
    this.sms = limitObj.sms || 0;
    this.spy_chat = limitObj.spy_chat || 0;
    this.status = limitObj.status || 0;
    this.support_24h = limitObj.support_24h || 0;
    this.support_phone = limitObj.support_phone || 0;
    this.support_sms_email = limitObj.support_sms_email || 0;
    this.switch_channel = limitObj.switch_channel || 0;
    this.telegram = limitObj.telegram || 0;
    this.timeline = limitObj.timeline || 0;
    this.transfer_attendance = limitObj.transfer_attendance || 0;
    this.trigger = limitObj.trigger || 0;
    this.two_steps_login = limitObj.two_steps_login || 0;
    this.type_magic = limitObj.type_magic || 0;
    this.user = limitObj.user || 0;
    this.video_conference = limitObj.video_conference || 0;
    this.virtual_agent = limitObj.virtual_agent || 0;
    this.voip = limitObj.voip || 0;
    this.web_sdk = limitObj.web_sdk || 0;
    this.webhook = limitObj.webhook || 0;
    this.whatsapp = limitObj.whatsapp || 0;
    this.whitelabel = limitObj.whitelabel || 0;
    this.workflow = limitObj.workflow || 0;
    this.worktime = limitObj.worktime || 0;
    this.worktime_support = limitObj.worktime_support || 0;
  }

  /**
     * Creates an instance of CompanyLimit.
     * @param { CompanyLimitObj } limitObj
     * @memberof CompanyLimit
     * @returns { CompanyLimit }
     */
  static init(limitObj) {
    return new CompanyLimit(limitObj);
  }
}
