//import { PlanPrice } from './PlanPrice'
//import { Package } from './Package'

/**
 * Plan obj
 * @typedef { Object } packageObj
 * @property { Number } packageObj.package_id
 * @property { String } packageObj.name
 * @property { String } packageObj.slug
 * @property { Boolean } packageObj.visible
 * @property { Boolean } packageObj.active
 * @property { String|Number } packageObj.order
 */

/**
 *
 * Plan
 * @class Plan
 */
export class Package {

  /**
     * Creates an instance of Plan.
     * @param { PlanObj } packageObj
     * @memberof Plan
     */

  constructor(packageObj) {
    if(typeof packageObj === 'undefined' || packageObj === null){
      packageObj = {};
    }

    this.id        = packageObj.id || null;
    this.name      = packageObj.name || null;
    this.slug      = packageObj.slug || null;
    this.visible   = packageObj.visible || null;
    this.order     = packageObj.order || null;
    this.active    = packageObj.active || null;
    this.agent     = packageObj.agent || 1;
  }

  /**
     * Creates an instance of Plan.
     * @param { PlanObj } packageObj
     * @memberof Plan
     * @returns { Plan }
     */
  static init(packageObj) {
    return new Package(packageObj);
  }
}
