//import { OrderPayment } from './OrderPayment'
//import { Package } from './Package'

import {Order} from "./Order";

/**
 *
 * OrderPayment
 * @class OrderPayment
 */
export class OrderPayment {

  /**
     * Creates an instance of OrderPayment.
     * @param { OrderPaymentObj } paymentObj
     * @memberof OrderPayment
     */

  constructor(paymentObj) {
    if(typeof paymentObj === 'undefined' || paymentObj === null){
      paymentObj = {};
    }

    this.id           = paymentObj.id || null;
    this.order_id   = paymentObj.order_id || null;
    this.amount       = paymentObj.amount || null;
    this.gateway      = paymentObj.getway || null;
    this.gateway_name = paymentObj.gateway_name || null;
    this.method       = paymentObj.method || null;
    this.reference    = paymentObj.reference || null;
    this.receipt      = paymentObj.receipt || null;
    this.created_at   = new Date();

    if(typeof paymentObj.order !== "undefined"){
      this.order = new Order(paymentObj.order);
    }

    if(typeof paymentObj.created_at !== "undefined"){
      this.created_at = new Date(paymentObj.created_at);
    }
  }

  /**
     * Creates an instance of OrderPayment.
     * @param { OrderPaymentObj } paymentObj
     * @memberof OrderPayment
     * @returns { OrderPayment }
     */
  static init(paymentObj) {
    return new OrderPayment(paymentObj);
  }

}
