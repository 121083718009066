/**
 *
 * OrderPaymentAttempt
 * @class OrderFailedPayment
 */
import {Order} from "./Order";

export class OrderFailedPayment {

  /**
     * Creates an instance of OrderPaymentAttempt.
     * @param { OrderPaymentAttemptObj } paymentObj
     * @memberof OrderPaymentAttempt
     */

  constructor(paymentObj) {
    if(typeof paymentObj === 'undefined' || paymentObj === null){
      paymentObj = {};
    }

    this.id         = paymentObj.id || null;
    this.order_id = paymentObj.order_id || null;
    this.payment_method = paymentObj.payment_method || null;
    this.payment_source = paymentObj.payment_source || null;
    this.response   = paymentObj.response || null;
    this.created_at = new Date();

    this.order      = (paymentObj.order) ? new Order(paymentObj.order) : new Order();

    if(typeof paymentObj.created_at !== "undefined"){
      this.created_at = new Date(paymentObj.created_at);
    }
  }

  /**
     * Creates an instance of OrderPaymentAttempt.
     * @param { OrderPaymentAttemptObj } paymentObj
     * @memberof OrderPaymentAttempt
     * @returns { OrderFailedPayment }
     */
  static init(paymentObj) {
    return new OrderFailedPayment(paymentObj);
  }

}
