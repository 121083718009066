/**
 *
 * OrderNotification
 * @class OrderNotification
 */
export class OrderNotification {

  /**
     * Creates an instance of OrderNotification.
     * @param { OrderNotificationObj } notificationObj
     * @memberof OrderNotification
     */

  constructor(notificationObj) {
    if(typeof notificationObj === 'undefined' || notificationObj === null){
      notificationObj = {};
    }

    this.id              = notificationObj.id || null;
    this.order_id        = notificationObj.order_id || null;
    this.notification_id = notificationObj.notification_id || null;
    this.to              = notificationObj.to || null;
    this.channel         = notificationObj.channel || null;
    this.status          = notificationObj.status || null;
    this.response        = notificationObj.response || null;
    this.created_at      = new Date();

    this.class        = 'badge badge-primary';

    switch (notificationObj.status) {
    case 'queued':
      this.class = 'badge badge-info';
      break;
    case 'sent':
      this.class = 'badge badge-warning';
      break;
    case 'clicked':
      this.class = 'badge badge-accent';
      break;
    case 'opened':
      this.class = 'badge badge-success';
      break;
    case 'error':
      this.class = 'badge badge-danger';
      break;
    case 'abusereport':
      this.class = 'badge badge-danger';
      break;
    case 'unsubscribed':
      this.class = 'badge badge-danger';
      break;
    }

    if(typeof notificationObj.created_at !== "undefined"){
      this.created_at = new Date(notificationObj.created_at);
    }
  }

  /**
     * Creates an instance of OrderNotification.
     * @param { OrderNotificationObj } notificationObj
     * @memberof OrderNotification
     * @returns { OrderNotification }
     */
  static init(notificationObj) {
    return new OrderNotification(notificationObj);
  }

}
