import {Subscription} from "./Subscription";

/**
 *
 * SubscriptionEvent
 * @class SubscriptionEvent
 */
export class SubscriptionEvent {

  /**
     * Creates an instance of SubscriptionEvent.
     * @param { SubscriptionEventObj } eventObj
     * @memberof SubscriptionEvent
     */

  constructor(eventObj) {
    if(typeof eventObj === 'undefined' || eventObj === null){
      eventObj = {};
    }

    this.id              = eventObj.id || null;
    this.subscription_id = eventObj.subscription_id || null;
    this.type            = eventObj.type || null;
    this.subscription    = new Subscription();
    this.period_start    = new Date();
    this.period_end      = new Date();
    this.created_at      = new Date();

    if(typeof eventObj.created_at !== "undefined"){
      this.created_at = new Date(eventObj.created_at);
    }

    if(typeof eventObj.period_start !== "undefined"){
      this.period_start = new Date(eventObj.period_start);
    }

    if(typeof eventObj.period_end !== "undefined"){
      this.period_end = new Date(eventObj.period_end);
    }

    if(typeof eventObj.subscription !== "undefined"){
      this.subscription  = new Subscription(eventObj.subscription);
    }
  }

  /**
     * Creates an instance of SubscriptionEvent.
     * @param { SubscriptionEventObj } eventObj
     * @memberof SubscriptionEvent
     * @returns { SubscriptionEvent }
     */
  static init(eventObj) {
    return new SubscriptionEvent(eventObj);
  }

}
