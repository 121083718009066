/**
 *
 * Company
 * @class Company
 */
import {CompanyLimit} from "./CompanyLimit";
import {CreditCard} from "./CreditCard";
import {Order} from "./Order";
import {Coupon} from "./Coupon";
import {Subscription} from "./Subscription";
import {Partner} from "./Partner";

export class Company {

  /**
     * Creates an instance of Company.
     * @param { CompanyObj } companyObj
     * @memberof Company
     */

  constructor(companyObj) {
    if(typeof companyObj === 'undefined' || companyObj === null){
      companyObj = {};
    }

    this.id                  = companyObj.id || null;
    this.partner_id          = companyObj.partner_id || null;
    this.country_id          = companyObj.country_id || null;
    this.name                = companyObj.name || null;
    this.business_name       = companyObj.business_name || null;
    this.email               = companyObj.email || null;
    this.nif                 = companyObj.nif || null;
    this.currency            = companyObj.currency || null;
    this.code                = companyObj.code || null;
    this.address             = null;
    this.street              = companyObj.street || null;
    this.number              = companyObj.number || null;
    this.complement          = companyObj.complement || null;
    this.district            = companyObj.district || null;
    this.zipcode             = companyObj.zipcode || null;
    this.city                = companyObj.city || null;
    this.state               = companyObj.state || null;
    this.phone               = companyObj.phone || null;
    this.phone_number        = companyObj.phone_number || null;
    this.phone_prefix        = companyObj.phone_prefix || '+55';
    this.active              = companyObj.active || 1;
    this.locale              = companyObj.locale || null;
    this.created_at          = new Date();

    this.plan                = companyObj.plan || null;
    this.agent               = companyObj.agent || 1;
    this.whatsapp            = companyObj.whatsapp  || 0;

    this.creditcards         = [];
    this.orders            = [];
    this.products            = [];
    this.coupons             = [];

    this.limits              = new CompanyLimit();

    this.subscription        = {};
    this.partner             = new Partner();

    if(this.street !== null){
      this.address = this.street + ", " + this.number + " - " +  this.district + "<br>" + this.city + ", " + this.state + " - " + this.zipcode;
    }

    if(typeof companyObj.partner !== "undefined"){
      this.partner = new Partner(companyObj.partner);
    }

    if(typeof companyObj.created_at !== "undefined"){
      this.created_at = new Date(companyObj.created_at);
    }

    if(typeof companyObj.subscription !== "undefined"){
      this.subscription = new Subscription(companyObj.subscription);
    }

    if(typeof companyObj.company_limit !== "undefined" && companyObj.company_limit !== null){
      this.limits = new CompanyLimit(companyObj.company_limit);
    }

    if(typeof companyObj.credit_cards !== "undefined"){
      this.creditcards  = companyObj.credit_cards.map(card => {
        return new CreditCard(card);
      }) || [];
    }

    if(typeof companyObj.orders !== "undefined"){
      this.orders  = companyObj.orders.map(order => {
        return new Order(order);
      }) || [];
    }

    if(typeof companyObj.coupons !== "undefined"){
      this.coupons = companyObj.coupons.map(coupon=> {
        return new Coupon(coupon);
      }) || [];
    }
  }

  /**
     * Creates an instance of Company.
     * @param { CompanyObj } companyObj
     * @memberof Company
     * @returns { Company }
     */
  static init(companyObj) {
    return new Company(companyObj);
  }
}
