/**
 *
 * Coupon
 * @class Coupon
 */
import {Order} from "./Order";

export class Coupon {

  /**
     * Creates an instance of Coupon.
     * @param { CouponObj } couponObj
     * @memberof Coupon
     */

  constructor(couponObj) {
    if(typeof couponObj === 'undefined' || couponObj === null){
      couponObj = {};
    }

    this.id         = couponObj.id || null;
    this.company_id = couponObj.company_id || null;
    this.name       = couponObj.name || null;
    this.token      = couponObj.token || null;
    this.type       = couponObj.type || 'amount';
    this.value      = couponObj.value || null;
    this.currency   = couponObj.currency || 'brl';
    this.duration   = couponObj.duration|| null;
    this.code       = couponObj.code || null;
    this.limit      = couponObj.limit || null;
    this.count      = couponObj.count || 0;

    this.start_at      = new Date();
    this.expire_at      = new Date();
    this.created_at      = new Date();

    if(typeof couponObj.start_at !== "undefined"){
      this.start_at = new Date(couponObj.start_at);
    }

    if(typeof couponObj.expire_at !== "undefined"){
      this.expire_at = new Date(couponObj.expire_at);
    }

    if(typeof couponObj.created_at !== "undefined"){
      this.created_at = new Date(couponObj.created_at);
    }

    if(typeof couponObj.orders === 'object'){
      this.orders = couponObj.orders.map(order=> {
        return new Order(order);
      });
    }else{
      this.orders = [];
    }

    this.period         = null;
  }

  /**
     * Creates an instance of Coupon.
     * @param { CouponObj } couponObj
     * @memberof Coupon
     * @returns { Coupon }
     */
  static init(couponObj) {
    return new Coupon(couponObj);
  }

}
